<template>
  <b-card title="">
    <div class="card-title">
      <div class="row">
        <div class="col-md-6">
          <h4 class="mb-0">{{ title }}</h4>
        </div>
        <div class="col-md-6 text-right">
          <button
            class="btn btn-info btn-icon mr-1 d-none"
            @click.prevent="displayForm = !displayForm"
          >
            <feather-icon icon="PlusIcon" />
          </button>
          <button
            class="btn btn-info btn-icon"
            @click.prevent="displayFilters = !displayFilters"
          >
            <feather-icon icon="FilterIcon" />
          </button>
        </div>
      </div>
    </div>
    <div class="m-lg-5 mb-5">
      <subscriptions-filters
        v-if="displayFilters"
        :filters.sync="filters"
        @updated="setPage(1)"
      />
    </div>
    <div v-if="displayForm == true" class="m-lg-5 mb-5">
      <h3>Ajouter un client</h3>
      <subscription-form
        :creation="true"
        :display.sync="displayForm"
        @created="onCreate"
      />
    </div>
    <table class="table table-hover">
      <tbody>
        <template v-for="subscription in subscriptions">
          <tr
            :key="subscription.id"
            @click.prevent="$router.push(`/subscriptions/${subscription.id}`)"
          >
            <td>
              <h4 class="mb-0">
                {{ subscription.plan.label }}
              </h4>
              {{ subscription.user.fullname }}
            </td>
            <td>
              <b
                v-if="subscription.status == 'ENABLED'"
                class="badge badge-success"
              >
                {{ $t(`SUBSCRIPTION.${subscription.status}`) }}
              </b>
              <b
                v-else-if="subscription.status == 'CANCELED'"
                class="badge badge-danger"
              >
                {{ $t(`SUBSCRIPTION.${subscription.status}`) }}
              </b>
              <b v-else class="badge badge-dark">
                {{ $t(`SUBSCRIPTION.${subscription.status}`) }}
              </b>
            </td>
            <td class="text-right">
              {{ $dayjs(subscription.start_at).format('DD-MM-YYYY') }}
            </td>
          </tr>
        </template>
      </tbody>
    </table>
    <pagination-custom
      :page="filters.page"
      :total="pagination.total"
      :per-page="filters.per_page"
      class="text-center mt-3"
      @change="setPage"
    />
    <!-- <pre>{{ subscriptions }}</pre> -->
    <!-- <pre>{{ pagination }}</pre> -->
    <!-- <pre>{{ filters }}</pre> -->
  </b-card>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import SubscriptionsFilters from './SubscriptionsFilters.vue'

const subscriptionsFields =
  'results{' +
  'id,status,start_at,' +
  'plan{label,price},user{fullname}' +
  '},meta'

export default {
  components: {
    BCard,
    SubscriptionsFilters,
  },
  data() {
    return {
      displayFilters: false,
      displayForm: false,
      subscriptions: [],
      pagination: { total: 0 },
      filters: {
        page: 1,
        per_page: 10,
        status: 'ENABLED',
      },
    }
  },
  computed: {
    title() {
      if (this.pagination.total) {
        return `${this.pagination.total} éléments - page ${this.pagination.page} / ${this.pagination.pages}`
      }
      return '…'
    },
  },
  watch: {
    $route() {
      this.init()
    },
  },
  async created() {
    this.filters = Object.assign(this.filters, this.$route.query)
    this.init()
  },
  methods: {
    async init() {
      const resp = await this.$http.get('/subscriptions/', {
        params: Object.assign(this.filters, this.$route.query),
        headers: {
          'X-Fields': subscriptionsFields,
        },
      })
      this.subscriptions = resp.data.results
      this.pagination = resp.data.meta
      this.$forceUpdate()
      this.scrollToTop()
    },
    setPage(page) {
      if (page) {
        this.filters.page = page
      }
      this.$router
        .push({
          path: this.$router.history.current.path,
          query: this.filters,
        })
        .catch((error) => {
          if (error.name !== 'NavigationDuplicated') {
            // throw error
            console.log(error)
          }
        })
    },
    scrollToTop() {
      window.scrollTo(0, 0)
    },
    onCreate(subscription) {
      this.displayForm = false
      this.$router.push(`/subscriptions/${subscription.id}/`)
    },
  },
}
</script>

<style></style>
