<template>
  <div>
    <b-form-group label-for="input-scheme">
      <b-form-select
        v-model="options.status"
        placeholder="Satut"
        @change="updateFilters()"
      >
        <b-form-select-option
          v-for="option in [
            'ENABLED',
            'PAUSE',
            'CANCEL_AT_PERIOD_END',
            'CANCELED',
          ]"
          :key="option"
          :value="option"
        >
          {{ $t(`SUBSCRIPTION.${option}`) }}
        </b-form-select-option>
      </b-form-select>
    </b-form-group>

    <!-- <pre>{{ options }}</pre> -->
  </div>
</template>
<script>
import { BFormGroup, BFormSelect, BFormSelectOption } from 'bootstrap-vue'

export default {
  name: 'IngredientsFilters',
  components: {
    BFormGroup,
    BFormSelect,
    BFormSelectOption,
  },
  props: {
    filters: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      options: {
        status: 'ENABLED',
      },
    }
  },
  created() {
    this.options = Object.assign(this.options, this.filters)
  },
  methods: {
    updateFilters() {
      this.$router
        .push({
          path: this.$router.history.current.path,
          query: Object.assign(this.options, { page: 1 }),
        })
        .catch((error) => {
          if (error.name !== 'NavigationDuplicated') {
            // throw error
            console.log(error)
          }
        })
    },
  },
}
</script>
